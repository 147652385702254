import React from 'react';

/**
 * SVG component that features a ☷ (kun) pattern decoration.
 */
export default function KunDecoration() {
  return (
    <svg
      className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
      width={404}
      height={384}
      fill="none"
      viewBox="0 0 404 384"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
          x={0}
          y={0}
          width={41}
          height={44}
          patternUnits="userSpaceOnUse"
        >
          <path
            d="M1,3.5h9m3,0h9m0,6h-9m-3,0h-9m0,6h9m3,0h9"
            // eslint-disable-next-line react/no-unknown-property
            stroke-width="2"
            stroke="currentColor"
            className="text-warm-gray-200"
          />
        </pattern>
      </defs>
      <rect
        width={404}
        height={384}
        fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
      />
    </svg>
  );
}
