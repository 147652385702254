import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';
import KunDecoration from '../components/KunDecoration';

export default function Contact() {
  return (
    <Layout>
      <Helmet>
        <title>Contact Taichi</title>
        <meta name="contact" content="Taichi Graphics Contact Page" />
      </Helmet>

      <main className="overflow-hidden">
        <div className="bg-warm-gray-50">
          <div className="py-24 lg:py-32">
            <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
                Contact Taichi Graphics
              </h1>
              <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
                We appreciate your interest in Taichi. Please take a few minutes
                to fill out the form below and we will get in touch shortly.
              </p>
            </div>
          </div>
        </div>
      </main>

      <section className="relative bg-white" aria-labelledby="contactHeading">
        <div
          className="absolute w-full h-1/2 bg-warm-gray-50"
          aria-hidden="true"
        />

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <KunDecoration />
        </div>

        <ContactForm />
      </section>

      <section aria-labelledby="officesHeading">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          {/* Placeholder for physical offices panel */}
        </div>
      </section>
    </Layout>
  );
}
