/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { MailIcon } from '@heroicons/react/outline';
import ReCAPTCHA from 'react-google-recaptcha';
import SafeLink from './SafeLink';

const TriangleDecoration = (
  <>
    <div
      className="absolute inset-0 pointer-events-none sm:hidden"
      aria-hidden="true"
    >
      <svg
        className="absolute inset-0 w-full h-full"
        width={343}
        height={388}
        viewBox="0 0 343 388"
        fill="none"
        preserveAspectRatio="xMidYMid slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
          fill="url(#linear1)"
          fillOpacity=".1"
        />
        <defs>
          <linearGradient
            id="linear1"
            x1="254.553"
            y1="107.554"
            x2="961.66"
            y2="814.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div
      className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
      aria-hidden="true"
    >
      <svg
        className="absolute inset-0 w-full h-full"
        width={359}
        height={339}
        viewBox="0 0 359 339"
        fill="none"
        preserveAspectRatio="xMidYMid slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
          fill="url(#linear2)"
          fillOpacity=".1"
        />
        <defs>
          <linearGradient
            id="linear2"
            x1="192.553"
            y1="28.553"
            x2="899.66"
            y2="735.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div
      className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
      aria-hidden="true"
    >
      <svg
        className="absolute inset-0 w-full h-full"
        width={160}
        height={678}
        viewBox="0 0 160 678"
        fill="none"
        preserveAspectRatio="xMidYMid slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
          fill="url(#linear3)"
          fillOpacity=".1"
        />
        <defs>
          <linearGradient
            id="linear3"
            x1="192.553"
            y1="325.553"
            x2="899.66"
            y2="1032.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </>
);

export interface ContactFormProps {
  title?: string,
  subtitle?: string,
}

/**
 * The contact form component to be used in contact page.
 */
export default function ContactForm({ title = 'Contact Taichi Graphics', subtitle = 'Contact information' }: ContactFormProps) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="relative bg-white shadow-xl">
        <h2 id="contactHeading" className="sr-only">
          {title}
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-3">
          <div className="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-gray-800 to-red-900 sm:px-10 xl:p-12">
            {TriangleDecoration}

            <h3 className="text-lg font-medium text-white">
              {subtitle}
            </h3>
            <p className="mt-6 text-base text-gray-50 max-w-3xl">
              Please make sure to include the details about why you are
              reaching out, thanks! Alternatively, you could email us
              directly. We strictly follow our
              {' '}
              <SafeLink isExternal={false} to="/privacy-policy/" className="underline">
                Privacy Policy
              </SafeLink>
              {' '}
              to handle your information.
            </p>
            <dl className="mt-8 space-y-6">
              <dt>
                <span className="sr-only">Phone number</span>
              </dt>
              {/* <dd className="flex text-base text-gray-50">
                <PhoneIcon
                  className="flex-shrink-0 w-6 h-6 text-gray-200"
                  aria-hidden="true"
                />
                <span className="ml-3">+1 (000) 123-4567</span>
              </dd> */}
              <dt>
                <span className="sr-only">Email</span>
              </dt>
              <dd className="flex text-base text-gray-50">
                <MailIcon
                  className="flex-shrink-0 w-6 h-6 text-gray-200"
                  aria-hidden="true"
                />
                <span className="ml-3">contact@taichi.graphics</span>
              </dd>
            </dl>
            <p className="mt-6 text-base text-gray-50">
              Looking for careers?
              {' '}
              <SafeLink
                isExternal
                to="https://app.mokahr.com/apply/taichi"
                className="font-medium text-gray-100 underline"
              >
                View all job openings
              </SafeLink>
              .
            </p>
            <ul className="mt-8 flex space-x-12">
              <li>
                <a
                  className="text-gray-200 hover:text-gray-100"
                  href="https://github.com/taichi-dev/taichi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">GitHub</span>
                  <svg
                    className="w-7 h-7"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          {/* Contact form, using Netlify-provided spam filter and API */}
          <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <h3 className="text-lg font-medium text-warm-gray-900">
              Send us a message
            </h3>

            <form
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact-taichi"
              data-netlify-recaptcha="true"
              data-netlify-honeypot="bot-field"
              className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                {/* Honeypot field for filtering spam bots */}
                <p className="hidden">
                  <label>
                    Don’t fill this out if you’re human:
                    {' '}
                    <input name="bot-field" />
                  </label>
                </p>

                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-warm-gray-900"
                >
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    autoComplete="given-name"
                    required
                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-warm-gray-900"
                >
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    autoComplete="family-name"
                    required
                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-warm-gray-900"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-warm-gray-900"
                  >
                    Phone
                  </label>
                  <span
                    id="phone-optional"
                    className="text-sm text-warm-gray-500"
                  >
                    Optional
                  </span>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                    aria-describedby="phone-optional"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium text-warm-gray-900"
                >
                  Subject
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    required
                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-warm-gray-900"
                  >
                    Message
                  </label>
                  <span
                    id="message-max"
                    className="text-sm text-warm-gray-500"
                  >
                    Max. 500 characters
                  </span>
                </div>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                    aria-describedby="message-max"
                    required
                  />
                </div>
              </div>

              <div>
                <ReCAPTCHA
                  badge="bottomright"
                  theme="light"
                  size="normal"
                  sitekey={`${process.env.GATSBY_SITE_RECAPTCHA_KEY}`}
                />
              </div>

              <div className="sm:col-span-2 sm:flex sm:justify-end">
                <button
                  type="submit"
                  className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium text-gray-100 bg-black hover:bg-gray-700 sm:w-auto focus:outline-none hover:text-white"
                >
                  Submit
                </button>
              </div>
              {/* To make Netlify Forms work for JSX
                  See: https://docs.netlify.com/forms/setup/#javascript-forms */}
              <input
                type="hidden"
                name="form-name"
                value="contact-taichi"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
